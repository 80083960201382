<template>
  <div>
    <ViewTemplateReportPreview :panel="panel" :templateList="templateList" :templateReport="templateReport" />
  </div>
</template>

<script>
import ViewTemplateReportPreview from "@nixweb/nixloc-ui/src/component/template/ViewTemplateReportPreview.vue";

export default {
  name: "ReportProductView",
  components: {
    ViewTemplateReportPreview,
  },
  data() {
    return {
      panel: {
        module: "Locação",
        title: "Locação por Cliente",
        showFilter: false,
        showSearch: false,
        showButtons: false,
      },
      templateList: {
        urlGetApi: "/odata/RentByCustomerOData",
        showChecks: false,
      },
      templateReport: {
        fields: [
          {
            field: "registeredDate",
            title: "Dt Cadastro",
            type: "date",
            orderBy: "asc",
            selected: true,
            requiredOData: false,
          },
          {
            field: "dateAccepted",
            title: "Dt Efetivação",
            type: "date",
            selected: false,
            requiredOData: false,
          },
          {
            field: "typeName",
            title: "Tipo",
            type: "text",
            selected: false,
            requiredOData: false,
          },
          {
            field: "number",
            title: "Número",
            type: "text",
            selected: true,
            requiredOData: false,
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "identification",
            title: "Identificação",
            type: "text",
            selected: true,
            requiredOData: false,
          },
          {
            field: "typeRentName",
            title: "Tipo Locação",
            type: "text",
            selected: false,
            requiredOData: false,
          },
          {
            field: "collaboratorName",
            title: "Consultor",
            type: "text",
            selected: true,
            requiredOData: false,
            styleHeader: "width:150px;",
            styleBody: "max-width: 150px; white-space: nowrap;",
          },
          {
            field: "companyName",
            title: "Razão Social/Nome",
            type: "text",
            selected: true,
            requiredOData: false,
            styleHeader: "width:250px;",
            styleBody: "max-width: 250px; white-space: nowrap;",
          },
          {
            field: "tradeName",
            title: "Fantasia/Apelido",
            type: "text",
            selected: true,
            requiredOData: false,
            styleHeader: "width:150px;",
            styleBody: "max-width: 150px; white-space: nowrap;",
          },
          {
            field: "contactName",
            title: "Contato",
            type: "text",
            selected: false,
            requiredOData: false,
            styleHeader: "width:120px;",
            styleBody: "max-width: 120px; white-space: nowrap;",
          },
          {
            field: "statusRentalName",
            title: "St Locação",
            type: "text",
            selected: false,
            requiredOData: false,
            fieldComparison: "statusRentalName",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            styleHeader: "width:130px;",
            styleBody: "max-width: 130px; white-space: nowrap;",
          },
          {
            field: "statusInvoiceName",
            title: "St Fatura",
            type: "text",
            selected: false,
            requiredOData: false,
            classCssTitle: "text-center",
            classCssBody: "text-center",
            styleHeader: "width:125px;",
            styleBody: "max-width: 125px; white-space: nowrap;",
          },
          {
            field: "total",
            title: "Total Loc",
            type: "currency",
            selected: true,
            requiredOData: false,
            styleHeader: "max-width:150px;",
            styleBody: "max-width: 150px; white-space: nowrap;",
          },
          {
            field: "totalPayment",
            title: "Total Pgto",
            type: "currency",
            selected: true,
            requiredOData: false,
            styleHeader: "max-width:150px;",
            styleBody: "max-width: 150px; white-space: nowrap;",
          },
        ],
        rules: [
          {
            name: "registeredDate",
            title: "Período",
            icon: "fas fa-database",
            typeField: "dateTime",
            type: "field",
            props: {
              format: "DD/MM/YYYY",
              type: "date",
              range: true,
              filterByField: [
                { text: "Data de Cadastro", value: "registeredDate" },
                { text: "Data de Efetivação", value: "dateAccepted" },
              ],
            },
            notDuplicate: true,
            isRequired: true,
          },
          {
            name: "type",
            title: "Tipo",
            icon: "fas fa-database",
            typeField: "checkbox",
            type: "field",
            options: [
              { title: "Avulsa", value: 1 },
              { title: "Longo Prazo", value: 2 },
            ],
            notDuplicate: true,
            isRequired: false,
          },
          {
            name: "number",
            title: "Número",
            icon: "fas fa-database",
            typeField: "numeric",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "identification",
            title: "Identificação",
            icon: "fas fa-database",
            typeField: "text",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "typeRentId",
            title: "Tipo de Locação",
            icon: "fas fa-database",
            typeField: "select",
            type: "field",
            props: {
              url: "/api/v1/rental/type-rent/select-all",
            },
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "collaboratorId",
            title: "Consultor",
            icon: "fas fa-database",
            typeField: "select",
            type: "field",
            props: {
              url: "/api/v1/human-resources/collaborator/select-all",
            },
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "customerSupplierId",
            title: "Cliente",
            icon: "fas fa-database",
            typeField: "select",
            type: "field",
            props: {
              url: "/api/v1/crm/customer/select-all",
            },
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "statusRental",
            title: "Status Locação",
            icon: "fas fa-database",
            typeField: "checkbox",
            type: "field",
            options: [
              { title: "Orçamento", value: 1 },
              { title: "Aprovada", value: 2 },
              { title: "Reprovada", value: 3 },
            ],
            notDuplicate: true,
            isRequired: false,
          },
          {
            name: "statusInvoice",
            title: "Status Faturamento",
            icon: "fas fa-database",
            typeField: "checkbox",
            type: "field",
            options: [
              { title: "Não Faturada", value: 1 },
              { title: "Faturada", value: 2 },
              { title: "Faturada Parcial", value: 3 },
              { title: "Não Fatura", value: 4 },
            ],
            notDuplicate: true,
            isRequired: false,
          },
          {
            name: "total",
            title: "Total",
            icon: "fas fa-database",
            typeField: "numeric",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "total",
            title: "Totalização",
            icon: "fas fa-sigma",
            typeField: "checkbox",
            classCss: "primary",
            type: "totalization",
            options: [
              {
                title: "Total Valor",
                value: "sum_Total",
                aggregate: {
                  title: "Total Valor",
                  value: "sumTotal",
                  type: "currency",
                },
              },
            ],
            notDuplicate: true,
            isRequired: false,
          },
        ],
      },
    };
  },
};
</script>
